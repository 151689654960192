import { LOGIN_PATH, PERMISSION_DENIED } from "@src/constants/routes";
import { FC, ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import Cookies from "js-cookie";
import decode from "jwt-decode";
import { IUserCustomClaimDto } from "@src/store/type";
interface Props {
  children?: ReactElement;
}

const AuthRoute: FC<Props> = () => {
  const [isLogged] = useLocalStorage("isLogged", false);
  const token = Cookies.get("idToken");
  if (!token) {
    return <Navigate to={LOGIN_PATH} />;
  }
  const payload: IUserCustomClaimDto = decode(token as string);

  if (isLogged) {
    if (payload?.permissions?.includes(16)) {
      return <Outlet />;
    }
    return <Navigate to={PERMISSION_DENIED} />;
  }
  return <Navigate to={LOGIN_PATH} />;
};

export default AuthRoute;
