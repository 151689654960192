import { StrictMode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { StyledEngineProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'

import * as ReactDOM from 'react-dom/client'

import App from './App'
import './index.css'
import { Helmet } from 'react-helmet';
import React from 'react';

const production = import.meta.env.VITE_MODE;

function shouldSendError(error: any) {
  if (error?.message?.includes("NetworkError") || error?.isBot) {
    return false; 
  }
  return true;
}
if (import.meta.env.VITE_MODE  === 'production') {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (shouldSendError(error)) {
        return event;
      }
      return null;
    },
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}
const FallbackComponent = () => {
  return <div>An error has occurred</div>;
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <>
   {production === 'production' ? (
      <Helmet>
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-CQT881R01M"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-CQT881R01M');
          `}
        </script>
      </Helmet>
    ) : (
      <></>
    )}
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
        <BrowserRouter>
         

            <App />
        
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </StyledEngineProvider>
  </StrictMode>
  </>,
)
