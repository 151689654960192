import React, { Suspense } from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";
import { RouteConfig } from "@src/types/index";
import UnauthRoute from "./UnauthRoute";
import AuthRoute from "./AuthRoute";
import { LayoutAuth, PageLayout, PageLayoutAccessDenied } from "@src/components/layout";
import RouteNotFound from "./RouteNotFound";
import HomePage from "@src/pages/Home";
import ExternalUser from "./ExternalUser";

const Login = React.lazy(() => import("@src/pages/Login"));
const NoPermission = React.lazy(() => import("@src/pages/NoPermission"));
const authRoutes: RouteConfig = {
  path: "/auth",
  element: <LayoutAuth />,
  guard: <UnauthRoute />,
  children: [
    {
      path: "login",
      element: <Login />,
    },
  ],
};

const gptRoutes: RouteConfig = {
  path: "/",
  element: <PageLayout />,
  guard: <AuthRoute />,
  children: [
    {
      path: "c/:groupId",
      element: <HomePage />,
    },
  ],
};

const permissionDeniedRoutes: RouteConfig = {
  path: "/external",
  element: <PageLayoutAccessDenied />,
  guard: <ExternalUser />,
  children: [
    {
      path: "permission-denied",
      element: <NoPermission />,
    },
  ],
};

const notfoundRoute: RouteConfig = {
  path: "*",
  element: <></>,
  guard: <RouteNotFound />,
  children: [
    {
      path: "*",
      element: <></>,
    },
  ],
};
const routes = [authRoutes, notfoundRoute, gptRoutes, permissionDeniedRoutes];

const Routes = () => {
  return (
    <Suspense fallback={<div />}>
      <ReactRoutes>
        {routes.map((route) => (
          <Route key={route.path} element={route.guard}>
            <Route path={route.path} element={route.element}>
              {route.children
                ? route.children.map(({ element, path, guard }) => (
                    <Route key={path} element={guard}>
                      <Route path={path} element={element} />
                    </Route>
                  ))
                : null}
            </Route>
          </Route>
        ))}
      </ReactRoutes>
    </Suspense>
  );
};
export default Routes;
