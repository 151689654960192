import { Box } from "@mui/material";
import { HOME_PATH } from "@src/constants/routes";
import Conversation from "@src/modules/chatGPT/Conversation";
import { useAppDispatch } from "@src/store";
import {
  actionGetConversationGroup,
  actionGetConversations,
  actionGetLatestConversation,
} from "@src/store/gpt/gpt.action";
import { IConversation } from "@src/store/type";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputTextChat from "@src/modules/chatGPT/InputTextChat";
import { askGptImg, askGptText } from "@src/api/gpt";
import { getLocalItem, setLocalItem } from "@src/utils";
const LIMIT = 5;

const HomePage = () => {
  const { groupId } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [controller, setController] = useState<AbortController>(new AbortController())
  const [loadingPage, setLoadingPage] = useState(true);
  const [isNewChat, setIsNewChat] = useState<boolean>(false)
  const [newChatNotReloadContent, setNewChatNotReloadContent] = useState(false);
  const [conversations, setConversations] = useState<IConversation[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const [model, setModel] = useState(() => {
    const storedModel = localStorage.getItem("selectedModel");
    if(storedModel){
      return JSON.parse(storedModel);
    }
    return null
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loadingDot, setLoadingDot] = useState(false);
  const [question, setQuestion] = useState("");

  // upload one img
  const [imgs, setImgs] = useState<[string] | null>(null); 
  const handleKeyPress = (e: any) => {
    const keyCode = e.keyCode || e.which;
    const questionAsking = getLocalItem('questionAsking', null);
 
    if (keyCode === 13 && questionAsking && !e.shiftKey) {
      // Enter key was pressed
      const btnSubmit = document.getElementById('btn-submit-input');
      if(btnSubmit){
        btnSubmit.click();
      }
    } 
 
  }

  const handleScroll = () => {
    const previousScrollPosition = getLocalItem('previousScroll', 0);
    let currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if(getLocalItem('autoScroll', false)){
      if ((currentScrollPosition + 50)< previousScrollPosition) {
        setLocalItem('autoScroll', false);
      }
    }
    
  }
  useEffect(() => {
    // Access the iframe by its ID or another query method
    const iframe: any = document.getElementById('jsd-widget');
    if(iframe){
      // Wait for the iframe to load its content
  
        // Access the iframe's document
        var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

        // Create a new style element
        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `
          #button-container #help-button.text>.help-button-contents {
            padding: 0px !important;
          }
          #button-container {
            width: 50px !important;
            width: 50px !important;
          }
        `;

        // Append the style element to the iframe's head
        iframeDocument.head.appendChild(style);

    }

    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
      document.removeEventListener('scroll', handleScroll);
    }
  }, [])

  // initial conversation
  useEffect(() => {
    if(groupId === 'new-chat'){
      setIsNewChat(true)
    }else{
      setIsNewChat(false)
    }
   
    if(newChatNotReloadContent && groupId !== 'new-chat'){
      setLoadingPage(false);
    }else{
      setLoadingPage(true);
      fetchInitialConversations(groupId);
     
    }

    const inputChat = document.getElementById("input-chat-position");
    if(inputChat){
      inputChat.focus();
    }

  }, [groupId]);

  //prevent empty content when view conversations
  useEffect(() => {
    if(conversations.length === 0 && groupId !== 'new-chat'){
      fetchInitialConversations(groupId)
    }
  }, [conversations, groupId, isNewChat])

  const fetchInitialConversations = (groupId: any) => {
    if(groupId === 'new-chat'){
      setLoadingPage(false);
      setConversations([]);
      return;
    }
    dispatch(
      actionGetConversations({
        groupId: Number(groupId),
        page: 1,
        limit: LIMIT,
      }),
    )
      .then((data) => {
        if (!data?.data.length) {
          return;
        }
        setConversations(data?.data?.reverse());
        setPage(1);
        setTotalPage(Math.ceil(data.totalCount / LIMIT));
        const frameChat = document.getElementById("frame-chat");
        setLoadingPage(false);
        if (frameChat) {
          setTimeout(() => {
            window.scrollTo({
              left:0, 
              top:frameChat?.getBoundingClientRect().height,
              behavior: 'instant'
            });
          }, 200);
        }
        
      })
      .catch(async (error) => {
        setLoadingPage(false);
        if(error?.status === 400){
          setConversations([]);
          if(groupId !== 'new-chat'){
            try {
              const data = await dispatch(actionGetLatestConversation());     
              navigate(`${HOME_PATH}${data?.groupId}`)  
            } catch (error) {
              navigate(HOME_PATH+'new-chat')
            }
          }
        }
      });
  }

  const handleAsk = useCallback(async () => {
    setLocalItem('autoScroll', true);
    if(!question.trim()){
      setLocalItem('autoScroll', false);
      return;
    }
    setIsLoading(true);
    setLoadingDot(true);
    const frameChat = document.getElementById("frame-chat");
    if (frameChat && getLocalItem('autoScroll', true)) {
      if (groupId === "new-chat") {
        frameChat.scrollTop = 0;
      } else {
        setTimeout(() => {
          window.scrollTo(0, frameChat?.getBoundingClientRect().height);
          setLocalItem('previousScroll', window.pageYOffset || document.documentElement.scrollTop)
        }, 500);
      }
    }

    let questions: { role: string; content: any }[] = [];
    if(imgs){
      questions.push({ role: "user", content: [
        {
          "type": "text",
          "text": question
        },
        ...imgs.map(img => ({
          "type": "image_url",
          "image_url": {
            "url": img
          }
        }))
      ] });
    }else{
      if (conversations.length <= 3) {
        conversations.forEach((ele) => {
          if(ele.type === 'text'){
            questions.push({ role: "user", content: ele.question });
            questions.push({ role: "assistant", content: ele.answer });
          }
          if(ele.type === 'image'){
            questions.push({ role: "user", content: ele.answer });
          }
        });
      } else {
        for (let i = conversations.length - 3; i < conversations.length; i++) {
          if(conversations[i].type === 'text'){
            questions.push({ role: "user", content: conversations[i].question});
            questions.push({ role: "assistant", content: conversations[i].answer });
          }
          if(conversations[i].type === 'image'){
            questions.push({ role: "user", content: conversations[i].answer });
          }
        }
      }
      questions.push({ role: "user", content: question });
    }
    
    let resAnswer = "";
    let filterQuestion = question;

    const body = {
      questions: groupId === 'new-chat' ? [{
        role: "user", content: imgs
          ? [
            {
              "type": "text",
              "text": question
            },
            ...imgs.map(img => ({
              "type": "image_url",
              "image_url": {
                "url": img
              }
            }))
          ] 
          : question
      }] : questions,
      model: model,
      groupId: Number(groupId),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    let askGptModel = imgs ? askGptImg({...body, type: 'vision-mode'}, controller.signal) : askGptText(body, controller.signal)
    askGptModel
      .then((res) => {
        const originalConversations = groupId === 'new-chat' ? [] : [...conversations]
        const inputChat = document.getElementById("input-chat-position");
        if (!Number(groupId)) {
          if (frameChat && getLocalItem('autoScroll', true)) {
            setTimeout(() => {
              window.scrollTo(0, frameChat?.getBoundingClientRect().height);
              setLocalItem('previousScroll', window.pageYOffset || document.documentElement.scrollTop)
           
            }, 200);
          }
        }
        if (res?.status === 203) {
          setIsLoading(false);
          setLoadingDot(false);
          const reader = res.body?.getReader();
          reader
            ?.read()
            .then((data) => {
              const { value } = data;
                const decoder = new TextDecoder();
                const text = decoder.decode(value);
                const payload = JSON.parse(text || JSON.stringify('{}'));
                if(payload){
                  toast(
                    payload?.data?.message,
                    { type: "warning" },
                  );
                }
            })
          
          if (inputChat) {
            inputChat.focus();
          }
        }else if(res.status === 200){
          if (frameChat && getLocalItem('autoScroll', true)) {
            window.scrollTo(
              0,
              frameChat?.getBoundingClientRect().height,
            );
            setLocalItem('previousScroll', window.pageYOffset || document.documentElement.scrollTop)
         
          }
          setQuestion("");
          setImgs(null);
          setIsLoading(false);
          if (inputChat) {
            inputChat.focus();
          }
          const reader = res.body?.getReader();
          const read = (flag: boolean) => {
            reader
              ?.read()
              .then((data) => {
                const { done, value } = data;
                const decoder = new TextDecoder();
                let text = '';

                if(value){
                  text = decoder.decode(value)
                }

                if (done) {
                  setLoadingDot(false);
                  if (inputChat) {
                    inputChat.focus();
                    setLocalItem('autoScroll', false);
                  }
                  setIsNewChat(false);
                  return;
                }
                if (flag) {
                  const getQuestion = text.split("\n\n\n\n");
                  filterQuestion = getQuestion[0] as string;
                  resAnswer += getQuestion[1];
                  flag = false;
                  read(false);
                } else {
                  let payload: any;
                  if (
                    //text?.includes(`{"status":${text.slice(10, text.length)}`)
                    text?.includes(`{"status":200,"data":{`)
                  ) {
                    payload = JSON.parse(text);
            
                    setLoadingDot(false);
                    if (!Number(groupId)) {
                      dispatch(actionGetConversationGroup()).then(() => {
                        setNewChatNotReloadContent(true);
                        fetchInitialConversations(payload?.data?.groupId)
                        setTimeout(() => {
                          setNewChatNotReloadContent(false);
                        }, 500)
                        navigate(HOME_PATH + payload?.data?.groupId);
                      });
                    }
                    if (inputChat) {
                      inputChat.focus();
                      setLocalItem('autoScroll', false);
                    }
                  
                    if (frameChat && getLocalItem('autoScroll', true)) {
                      window.scrollTo(
                        0,
                        frameChat?.getBoundingClientRect().height,
                      );
                      setLocalItem('previousScroll', window.pageYOffset || document.documentElement.scrollTop)
                
                    }
                    return;
                 
                  }
                  if (!payload?.status) {
                    
                    resAnswer += text;
                    if(text && !text?.includes(`{"status":200,"data":{`)){
                      setIsNewChat(false);
                      setConversations([...originalConversations, {
                        question: filterQuestion,
                        answer: resAnswer,
                        model: model,
                        type:imgs ? 'image' : 'text'
                      }]);  
                     
                    }
                    if(text.length > 30){
                      
                      if (frameChat && getLocalItem('autoScroll', true)) {
                        window.scrollTo(
                          0,
                          frameChat?.getBoundingClientRect().height,
                        );
                        setLocalItem('previousScroll', window.pageYOffset || document.documentElement.scrollTop)         
                      }
                    }
                  } else {
                    
                    if (payload?.status !== 200) {
                      setIsNewChat(false);
                      setLoadingDot(false);
                      setLocalItem('autoScroll', false);
                      toast(payload.message, { type: "error" });
                    }
                  }
                  read(false);
                }
              })
              .catch((err) => {
                //console.log("error from stream api:::", err?.message);
                setLoadingDot(false);
                setLoadingPage(false);
                setLocalItem('autoScroll', false);
                if(err?.message !== 'BodyStreamBuffer was aborted'){
                  if (!Number(groupId)) {
                    dispatch(actionGetConversationGroup()).then((data) => {
                      setNewChatNotReloadContent(true);
                      fetchInitialConversations(data[0]?.id)
                      setTimeout(() => {
                        setNewChatNotReloadContent(false);
                      }, 500)
                      navigate(HOME_PATH + data[0]?.id);
                    });
                  }
                  if (inputChat) {
                    inputChat.focus();
                  }
                  controller.abort();
                }

                if(err?.message.includes('JSON')){
                  setTimeout(() => {
                    fetchInitialConversations(groupId)
                    if (inputChat) {
                      inputChat.focus();
                    }
                  }, 200)
                }
                if (inputChat) {
                  inputChat.focus();
                }
              });
          };
          read(true);
        } 
        else {
          toast("Internal Server Error, Please try again!", { type: "error" });
          setLoadingDot(false);
          setIsLoading(false);
          setLocalItem('autoScroll', false);
          console.log("error", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
        setLoadingDot(false);
        setLocalItem('autoScroll', false);
        setController(() => new AbortController());
        if(err === 'cancelled stream'){
         
          setTimeout(() => {
            const btnSubmit2 = document.getElementById('btn-submit-input');
            if(btnSubmit2){
              btnSubmit2.click();
            }
          }, 500)
           
        }else{

          setTimeout(() => {
            const btnSubmit = document.getElementById('btn-submit-input');
            if(btnSubmit){
              btnSubmit.click();
            }
          }, 200)
        }
      });
  }, [question, model, controller, groupId]);

  return (
    <Box className="pb-[115px]" id="frame-chat">
      <Conversation
        isNewChat={isNewChat}
        page={page}
        isLoading={isLoading}
        conversations={conversations}
        setConversations={setConversations}
        totalPage={totalPage}
        setTotalPage={setTotalPage}
        groupId={groupId}
        setPage={setPage}
        loadingPage={loadingPage}
      />
      <InputTextChat
        cancelQuestion={controller}
        model={model}
        setModel={setModel}
        handleAsk={handleAsk}
        isLoading={isLoading}
        question={question}
        setQuestion={setQuestion}
        loadingDot={loadingDot}
        imgs={imgs}
        setImgs={setImgs}
      />
    </Box>
  );
};

export default HomePage;
