import { LOGIN_PATH } from "@src/constants/routes";
import { FC, ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import Cookies from "js-cookie";

interface Props {
  children?: ReactElement;
}

const ExternalUser: FC<Props> = () => {
  const [isLogged] = useLocalStorage("isLogged", false);
  const token = Cookies.get("idToken");
  if (!token || !isLogged) {
    return <Navigate to={LOGIN_PATH} />;
  }
  return <Outlet />;
};

export default ExternalUser;
