
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@mui/material'
import { useAppSelector } from '@src/store';

interface IDialogProps extends DialogProps {
  title: string;
  type: 'error' | 'success';
  content: any;
  cancelText?: string;
  okText?: string;
  onCancel?: () => void;
  onOk?: () => void;
}

const MuiDialogConfirm = ({ ...IDialogProps }: IDialogProps) => {
  const loading = useAppSelector(({gpt}) => gpt.loadingSubmitDelete)
  return (
    <Dialog
      className="z-[99999]"
      id="dialog-confirm"
      open={IDialogProps.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {IDialogProps?.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {IDialogProps?.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="outlined" onClick={IDialogProps.onCancel} autoFocus>
          {IDialogProps.cancelText || 'Cancel'}
        </Button>
        <Button disabled={loading ? true : false} startIcon={loading ? <CircularProgress size={16} /> : <></>} size="small" variant="outlined" onClick={IDialogProps.onOk} color="error">{IDialogProps.okText || 'Delete'}</Button>
      </DialogActions>
    </Dialog>
)
}

export default MuiDialogConfirm
