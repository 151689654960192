import { TextField, TextFieldProps } from '@mui/material'
import { twMerge } from 'tailwind-merge'

export type TMuiInputTextProps = TextFieldProps

const MuiInputText = ({
  name,
  className,
  inputProps,
  InputLabelProps,
  InputProps,
  sx,
  value,
  size = 'small',
  variant = 'outlined',
  type = 'text',
  ...restProps
}: TMuiInputTextProps) => (
  <TextField
    name={name}
    type={type}
    size={size}
    variant={variant}
    className={twMerge(
      'bg-[#fff] border border-[#C7C9D9] rounded-md',
      'placeholder:text-sm placeholder:font-normal placeholder:text-[#8F90A6]',
      className,
    )}
    InputProps={{
      ...InputProps,
      className: twMerge('text-sm font-normal text-dark-2 placeholder:text-dark-3', InputProps?.className),
      inputProps: {
        ...inputProps,
        className: twMerge('text-sm font-normal text-dark-2 placeholder:text-dark-3', inputProps?.className),
      },
    }}
    InputLabelProps={{
      ...InputLabelProps,
      className: twMerge('text-sm flex items-center w-full', InputLabelProps?.className),
    }}
    value={value || undefined}
    sx={{
      ...sx,
      '.MuiInputBase-root': { borderRadius: '8px' },
    }}
    {...restProps}
  />
)
export default MuiInputText
