import { FirebaseError } from "firebase/app";

export interface IAuthError {
  code: string;
  message: string;
}

export const formatErrorFirebase = (error: FirebaseError): IAuthError => {
  switch (error.code) {
    case "auth/wrong-password":
      return {
        code: "wrong-password",
        message: "Wrong password. Please try again.",
      };
    case "auth/user-not-found":
      return {
        code: "user-not-found",
        message: "User not found. Please try again.",
      };
    case "auth/too-many-requests":
      return {
        code: "too-many-requests",
        message: "Too many failed login attempts. Please try again later.",
      };
    case "auth/invalid-email":
      return {
        code: "invalid-email",
        message:
          "Invalid email address. Please check your email and try again.",
      };
    case "auth/email-already-in-use":
      return {
        code: "email-already-in-use",
        message:
          "Email already in use. Please choose a different email address.",
      };
    case "auth/weak-password":
      return {
        code: "weak-password",
        message: "Password is too weak. Please choose a stronger password.",
      };
    default:
      return {
        code: "unknown",
        message: "Login failed. Please try again later.",
      };
  }
};
