import React, { ReactNode } from 'react'

import Typography, { TypographyProps } from '@mui/material/Typography'

interface BaseTypographyProps extends TypographyProps {
  children: ReactNode
}

const BaseTypography: React.FC<BaseTypographyProps> = ({
  variant = 'body1',
  color = 'inherit',
  align = 'inherit',
  gutterBottom = false,
  noWrap = false,
  children,
  ...props
}) => {
  // Customize the styles based on your needs
  const styles: React.CSSProperties = {
    // Add your desired styles here
  }

  return (
    <Typography
      variant={variant}
      color={color}
      align={align}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      style={styles}
      {...props}
    >
      {children}
    </Typography>
  )
}

export default BaseTypography
