
import { BodyUploadUrl } from "@src/types/api";
import { baseApi } from "./baseClient";
const GET_URL_API = "/url-upload";


export const getUrlUpload = async (body: BodyUploadUrl[]) => {
  return await baseApi().post(
    GET_URL_API,
    body
  );
};
