interface Props {
  size?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
  color?: string;
}
const TALogo = ({className, color="#111C4E"}: Props) => {
  return (
    <svg className={className + ' dark:text-dark-txtAnswer'} width="36" height="36" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.6702 0C10.5979 0 0 10.5558 0 23.5763C0 36.5968 10.5979 47.1527 23.6702 47.1527H47.3404V23.5763C47.3404 10.5558 36.7425 0 23.6702 0ZM39.4516 36.676H31.5628V30.39C31.5628 29.5232 32.2703 28.8184 33.1405 28.8184H39.4516V36.676ZM39.4516 21.6129H28.1386C26.4057 21.6204 25.002 23.0261 24.9982 24.7559V43.2258H23.1556C20.5412 43.2258 18.4223 42.163 18.4223 39.5589V21.6129H7.8888C6.14456 21.6129 5.2592 20.2072 5.2592 18.4699V15.0631H35.7701C38.3846 15.0631 39.4516 18.485 39.4516 21.0891V21.6129Z" fill={color}/>
</svg>
    
  )
}

export default TALogo;