import axios from "axios";
import Cookies from "js-cookie";
import { firebaseAuth } from "@src/libs/firebase";
import { toast } from "react-toastify";
import { onAuthStateChanged } from "firebase/auth";
import { setConversationGroup } from "@src/store/gpt/gpt.slice";

export const BASE_URL =
  import.meta.env.VITE_APP_SERVER_GPT ||
  "https://chatgpt-be-yxg7x4oxfa-uc.a.run.app/api";
import store from '@src/store';
export const baseApi = () => {
  let baseClient = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
  });

  baseClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (!error?.response) {
        toast('Please check your network', {type: 'error'})
        return Promise.reject({
          ...error,
          response: {
            status: 500,
            message: "Please check your network",
          },
        });
      }
      if (error.response.status === 401) {
        onAuthStateChanged(firebaseAuth, async (user) => {
          if (user) {
            try {
              const idToken = await user.getIdToken(true); // Force refresh the token
              Cookies.set("idToken", idToken);
              originalRequest.headers['Authorization'] = `Bearer ${idToken}`;
              // Cannot relay API because react-query provides if API failed
              if(originalRequest.url === '/groups'){
                const {data} = await baseClient(originalRequest);
                store.dispatch(setConversationGroup(data?.data))
              }else{
                return  baseClient(originalRequest);
              }
            } catch (error) {
              //force logout when refresh token failed
              Cookies.remove("idToken");
              localStorage.setItem("isLogged", JSON.stringify(false));
              return Promise.reject(error);
            }
          }
        })
      } else {
        return Promise.reject(error);
      }
    },
  );

  baseClient.interceptors.request.use((config: any) => {
    const token = Cookies.get("idToken");
    config.headers.Authorization = "Bearer " + token;
    return config;
  });

  return baseClient;
};

// config fetch
const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  let [resource, config] = args as any;
  let response = await originalFetch(resource, config);
  if (!response.ok && response.status === 401) {
    const user = firebaseAuth.currentUser;
    if (user) {
      try {
        const idToken = await user.getIdToken(true); // Force refresh the token
        Cookies.set("idToken", idToken);
        return fetch(resource, {
          ...config,
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        });
      } catch (error) {
        //force logout when refresh token failed
        Cookies.remove("idToken");
        localStorage.setItem("isLogged", JSON.stringify(false));
        return Promise.reject(error);
      }
    }
  }
  return response;
};