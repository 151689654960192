import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY || 'AIzaSyA9rUobgdk6GvPqjSPADBTWGHlv35XWeEw',
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN || 'phoenix-393903.firebaseapp.com',
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET || 'phoenix-393903.appspot.com',
}
const firebaseApp = initializeApp(firebaseConfig)
const firebaseAuth = getAuth(firebaseApp)
const storage = getStorage(firebaseApp)
if (import.meta.env.VITE_MODE !== 'production') {
  const tenantId = import.meta.env.VITE_TENTANT_ID || 'phoenix-test-x14a4'
  firebaseAuth.tenantId = tenantId
}

export { firebaseAuth, storage }

export default firebaseApp

export * from './auth'
export * from './type'