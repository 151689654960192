import { QueryConversations } from "@src/types/api";
import { baseApi } from "./baseClient";
import Cookies from "js-cookie";
//const ASK_API = '/ask';
const GET_CONVERSATIONS_API = "/conversations";
const GET_GROUP_CONVERSATION_API = "/groups";
const GROUP_CONVERSATION_API = "/group";
const LATEST_CONVERSATION_API = "/latest-conversation";
const MODEL_LIST_API="/models";
const CLIENT_ABORTED_API="/client-aborted";
export const getConversationGroupAPI = async () => {
  return await baseApi().get(GET_GROUP_CONVERSATION_API);
};

export const getConversationsAPI = async (query: QueryConversations) => {
  return await baseApi().get(
    `${GET_CONVERSATIONS_API}?groupId=${query.groupId}&page=${query.page}&limit=${query.limit}`,
  );
};

export const delConversationGroupAPI = async (groupId: number) => {
  return await baseApi().delete(`${GROUP_CONVERSATION_API}?groupId=${groupId}`);
};

export const editConversationGroupAPI = async (
  groupId: number,
  body: {
    summary: string;
  },
) => {
  return await baseApi().put(
    `${GROUP_CONVERSATION_API}?groupId=${groupId}`,
    body,
  );
};

export const getLatestConvesation = async () => {
  return await baseApi().get(LATEST_CONVERSATION_API);
};

export const getModelList = async () => {
  return await baseApi().get(MODEL_LIST_API);
}

export const clientAbort = async () => {
  return await baseApi().get(CLIENT_ABORTED_API);
}

// call api follow fetch to stream api
export const askGptText = async (body: {
  questions: Array<any>,
  model: string,
  groupId: number,
  timeZone: string,
}, signal: any) => {
  const token = Cookies.get("idToken");
  return fetch(`${import.meta.env.VITE_APP_SERVER_GPT}/ask`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal: signal
  })
}

// call api follow fetch to stream api
export const askGptImg = async (body: {
  questions: Array<any>,
  model: string,
  groupId: number,
  timeZone: string,
  type: string
}, signal: any) => {
  const token = Cookies.get("idToken");
  return fetch(`${import.meta.env.VITE_APP_SERVER_GPT}/ask-img`, {
    method: "POST",
    body: JSON.stringify({
      ...body,
      model:'gpt-4o'
    }),
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal: signal
  })
}