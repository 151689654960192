import { Avatar } from "@mui/material";
import TALogo from "@src/shared/icons/TALogo";
import { useAppSelector } from "@src/store";
import { getInitials } from "@src/utils";

type Props = {
  fullName?: string;
}
const UserAvatar = ({ fullName }: Props) => {
 
  const initials = getInitials(fullName);
  const theme = useAppSelector(({gpt}) => gpt.theme)
  if (initials) {
    return (
      <Avatar variant="square" className='w-[36px] h-[36px]' sx={{
        fontSize:'16px',
        background: '#19c37d'
      }}>
        {initials}
      </Avatar>
    );
  }

  return  <Avatar variant="square" className="w-[36px] h-[36px] bg-transparent" sx={{
    fontSize:'16px'
  }}>
    <TALogo color={theme === 'dark' ? '#ffff' : undefined}/>
  </Avatar>
}

export default UserAvatar