import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from './gpt.state';
import { IConversationGroup, IModel } from '../type'

export const gptSlice = createSlice({
  name: "gpt",
  initialState,
  reducers: {
    setConversationGroup: (state, { payload }: PayloadAction<IConversationGroup[] | null >) => {
      state.groups = payload
    },
    setLoadingSubmitDelete: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingSubmitDelete = payload
    },
    setTheme: (state, { payload }: PayloadAction<string>) => {
      state.theme = payload
    },
    setModels: (state, { payload }: PayloadAction<IModel[]>) => {
      state.models = payload
    }
  }
});

export const { 
  setConversationGroup,
  setLoadingSubmitDelete,
  setTheme,
  setModels
} = gptSlice.actions;

export default gptSlice.reducer;