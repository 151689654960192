interface Props {
  width?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
  color?: string;
}
const CollapseIcon = ({className, width = 18, height = 18, color}: Props) => {
  return (
    <svg 
      stroke="currentColor" 
      fill="none" 
      strokeWidth="2" 
      viewBox="0 0 24 24" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      className={className + ' dark:text-dark-txtAnswer'} 
      height={height} 
      width={width} 
      color={color}
      xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2">
        </rect>
        <line x1="9" y1="3" x2="9" y2="21">
        </line>
    </svg>
  )
}

export default CollapseIcon;