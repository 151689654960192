

interface Props {
  width?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
  color?: string;
}
const StopIcon = ({className, width=18, height=18}: Props) => {
  return (
    <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 16 16" fill="currentColor" 
    className={"h-2 w-2 text-gizmo-gray-950 dark:text-gray-200 " + className} 
    height={width} 
    width={height}>
      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z" 
      strokeWidth="0"></path></svg>
  )
}

export default StopIcon;
