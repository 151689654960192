
import { MuiLayoutHeader, MuiLayoutSidebar } from '../common'
import { Outlet, useLocation } from 'react-router-dom';
import { useSidebar } from '@src/hooks/useSideBar'
import { useAppDispatch, useAppSelector } from '@src/store'
import { selectAuthUser } from '@src/store/auth/auth.selector';
import { IconButton } from '@mui/material'

import CollapseIcon from '@src/shared/icons/CollapseIcon'
import { setCollapse } from '@src/store/auth/auth.slice'

const PageLayout = () => {
  const location = useLocation()
  const dispatch = useAppDispatch();
  const { sidebarItems, selectedListItem, setSelectedListItem } = useSidebar({
    currentPath: location.pathname,
  })
  const currentUser = useAppSelector(selectAuthUser)
  
  return (
    <>
      <section className="flex justify-start items-start dark:bg-slate-700">
        <MuiLayoutSidebar
          items={sidebarItems}
          selectedKey={selectedListItem}
          setSelectedKey={setSelectedListItem}
          classes={{ paper: 'shadow-sidebar w-[330px]' }}
          
        />
        <section className="w-full h-full dark:bg-slate-700 dark:text-white-0 overflow-x-hidden">
          <MuiLayoutHeader
            logo={
            <IconButton onClick={() => {
              dispatch(setCollapse(true))
            }}>
              <CollapseIcon height={24} width={24} color='black'/>
            </IconButton>}
            position="fixed"
            className="w-full h-[91px] z-[1201] fixed"
            username={currentUser?.name || currentUser?.email}
          />
          <main className="grow mt-[5rem] pt-4 pb-4 dark:bg-slate-700 dark:text-white-0 min-h-[calc(100vh-64px)]">
            <Outlet/>
          </main>
        </section>
      </section>
    </>
)}

export default PageLayout
