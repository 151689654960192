import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { logoutUser } from './auth.action'
import { initialState } from './auth.state'
import { ICurrentUser } from '../type'

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoadingState: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setCurrentUser: (state, { payload }: PayloadAction<ICurrentUser | null>) => {
      state.currentUser = payload
    },
    setCollapse: (state, { payload }: PayloadAction<boolean>) => {
      state.collapse = payload
    }
  },
  
  extraReducers: (builder) => {
    builder.addCase(logoutUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.isLoading = false
      state.currentUser = null
    })
    builder.addCase(logoutUser.rejected, (state) => {
      state.isLoading = false
    })
  },
});

export const { 
  setLoadingState,
  setCurrentUser,
  setCollapse
} = authSlice.actions;

export default authSlice.reducer;