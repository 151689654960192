interface Props {
  size?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
  color?: string;
}
const EditIcon = ({className}: Props) => {
  return (
    <svg 
    stroke="currentColor" 
    fill="none" 
    strokeWidth="2" 
    viewBox="0 0 24 24" 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    className={className + ' dark:text-dark-txtAnswer'} 
    height={16} 
    width={16} 
    xmlns="http://www.w3.org/2000/svg">
      <path d="M12 20h9">
      </path>
      <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z">
      </path>
    </svg>
  )
}

export default EditIcon;