import { useEffect, useMemo, useState } from "react";
import ChatIcon from "@src/shared/icons/ChatIcon";
import { ISidebarItem } from "@src/components/common";
import { compact } from "@src/utils";
import { useAppDispatch, useAppSelector } from "@src/store";
import { actionGetConversationGroup } from "@src/store/gpt/gpt.action";
import { setConversationGroup } from "@src/store/gpt/gpt.slice";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import decode from "jwt-decode";
import { IUserCustomClaimDto } from "@src/store/type";
interface IUseSidebarProps {
  isAdmin?: boolean;
  currentPath: string;
}

export const useSidebar = ({ currentPath }: IUseSidebarProps) => {
  const [selectedListItem, setSelectedListItem] = useState("");
  const groups = useAppSelector(({ gpt }) => gpt.groups);
  const { groupId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actionGetConversationGroup());
    return () => {
      dispatch(setConversationGroup(null));
    };
  }, []);
  useEffect(() => {
    groups?.forEach((ele) => {
      if (groupId === `${ele.id}`) {
        setSelectedListItem(`${ele.id}`);
        return;
      }
    });

    if (groupId === "new-chat") {
      setSelectedListItem("new-chat");
    }
  }, [currentPath, groups]);

  const sidebarItems: ISidebarItem[] = useMemo(() => {
    const token = Cookies.get("idToken");
    const payload: IUserCustomClaimDto = decode(token as string);
    const list: any = groups?.map((ele) => {
      
      if (!payload?.permissions?.includes(16)) {
        return {
          key: `${ele.id}`,
          name: ele.summary,
          path: `/c/${ele.id}`,
          icon: <ChatIcon />,
          disabled: true
        };
      }
      return {
        key: `${ele.id}`,
        name: ele.summary,
        path: `/c/${ele.id}`,
        icon: <ChatIcon />,
      };
    });
    if (list) {

      return compact([
        {
          key: `new-chat`,
          name: "New Chat",
          path: `/c/new-chat`,
          icon: <ChatIcon />,
          disabled: !payload?.permissions?.includes(16)
        },
        ...list,
      ]);
    } else {
      return [
        {
          key: `new-chat`,
          name: "New Chat",
          path: `/c/new-chat`,
          icon: <ChatIcon />,
          disabled: !payload?.permissions?.includes(16)
        },
      ];
    }
  }, [groups]);

  return {
    sidebarItems,
    selectedListItem,
    setSelectedListItem,
  };
};
