interface Props {
  size?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
  color?: string;
}
const VIcon = ({className}: Props) => {
  return (
    <svg 
      stroke="currentColor" 
      fill="none" 
      strokeWidth="2" 
      viewBox="0 0 24 24" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      className={className + ' dark:text-dark-txtAnswer'} 
      height="1em" 
      width="1em" 
      xmlns="http://www.w3.org/2000/svg"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
  )
}

export default VIcon;