interface Props {
  size?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
  color?: string;
}
const LogoutIcon = ({className}: Props) => {
  return (
    <svg className={className + ' dark:text-dark-txtAnswer'}  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 16V18C16 20.2091 14.2091 22 12 22H7C4.79086 22 3 20.2091 3 18V6C3 3.79086 4.79086 2 7 2H12C14.2091 2 16 3.79086 16 6V8" stroke="#717171" strokeWidth="1.5" strokeLinecap="round"></path><path d="M19 15L21.2929 12.7071C21.6834 12.3166 21.6834 11.6834 21.2929 11.2929L19 9" stroke="#717171" strokeWidth="1.5" strokeLinecap="round"></path><path d="M21 12L9 12" stroke="#717171" strokeWidth="1.5" strokeLinecap="round"></path></svg>
  )
}

export default LogoutIcon;