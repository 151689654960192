import { ICurrentUser } from '../type'

export interface IAuthState {
  currentUser: ICurrentUser | null
  isLoading: boolean | null
  collapse: boolean
}

export const initialState: IAuthState = {
  isLoading: null,
  currentUser: null,
  collapse: true
}
