import { ReactNode, useEffect, useState } from 'react'
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { LogoutOutlined } from '@mui/icons-material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { AppBar, AppBarProps, Box, IconButton, Menu, MenuItem, Toolbar, ToolbarProps } from '@mui/material'
import { twMerge } from 'tailwind-merge'
import { useLocalStorage } from 'usehooks-ts'
import {setTheme as setThemeGpt} from '@src/store/gpt/gpt.slice';
import { logoutUser } from '@src/store/auth/auth.action'
import { useAppDispatch } from '@src/store'

import { MuiAvatar } from '../avatar'
import { MuiTooltip } from '../tooltip'
import { MuiTypography } from '../typography'

interface ILayoutHeaderProps extends AppBarProps {
  logo: ReactNode
  className?: string
  username?: string
  toolbarProps?: ToolbarProps
}

const LayoutHeader = ({
  logo,
  className,
  username = 'Anonymous',
  position = 'static',
  toolbarProps,
  ...restProps
}: ILayoutHeaderProps) => {
  const dispatch = useAppDispatch()
  const [theme, setTheme] = useLocalStorage('theme', 'light')
  const [darkMode, setDarkMode] = useState(theme);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [isLogged, setIsLogged] = useLocalStorage('isLogged', false)
  
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  useEffect(() => {
    if(darkMode === 'dark'){
      document.getElementById('root')?.classList.add('dark');
    }else{
      document.getElementById('root')?.classList.remove('dark');
    }
  }, [darkMode])

  const handleLogout = () => {
    isLogged && setIsLogged(false)
    dispatch(logoutUser())
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const toggerTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setDarkMode(newTheme);
    setTheme(newTheme);
    dispatch(setThemeGpt(newTheme))
  }

  return (
    <AppBar position={position} className={twMerge('w-full bg-white dark:bg-slate-700 shadow-header', className)} {...restProps}>
      <Toolbar
        disableGutters
        className={twMerge('flex justify-between items-center', 'w-full h-[91px] px-8 py-3', toolbarProps?.className)}
        {...toolbarProps}
      >
        {logo}
        <section className="flex justify-end items-center gap-2">
          {/* <Box>
          <Typography className='font-heading font-noma' sx={{
            fontSize: "18px",
            lineHeight: "26px",
            fontWeight: 400,
            color: '#161E54'
          }}>Chat GPT User</Typography>
          </Box> */}
          <Box sx={{ flexGrow: 0 }}>
            <MuiTooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <MuiAvatar alt="Remy Sharp">
                  <AccountCircleIcon />
                </MuiAvatar>
              </IconButton>
            </MuiTooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key="name">
                <MuiTypography variant="subtitle2" className="text-slate-700 font-bold">
                  {username}
                </MuiTypography>
              </MenuItem>

              <MenuItem key="theme" onClick={toggerTheme}>
                <MuiTypography variant="subtitle2" className="text-slate-700 font-bold">
                  Theme {darkMode === 'dark' ? <DarkModeIcon /> : <LightModeIcon/>}
                </MuiTypography>
              </MenuItem>
           
              <MenuItem key="logout" onClick={handleLogout}>
                <MuiTypography variant="subtitle2" textAlign="center">
                  <LogoutOutlined /> Log out
                </MuiTypography>
              </MenuItem>
            </Menu>
          </Box>
        </section>
      </Toolbar>
    </AppBar>
  )
}

export default LayoutHeader
