import { useCallback, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Drawer, DrawerProps, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { twMerge } from 'tailwind-merge'

import { ISidebarItem } from './type'
import IcLogo from '@src/shared/icons/IcLogo'
import LogoutIcon from '@src/shared/icons/LogoutIcon'
import { useLocalStorage } from 'usehooks-ts'
import { useAppDispatch, useAppSelector } from '@src/store'
import { logoutUser } from '@src/store/auth/auth.action'
import DeleteIcon from '@src/shared/icons/DeleteIcon';
import EditIcon from '@src/shared/icons/EditIcon';
import { actionDeleteConversationGroup, actionEditConversationGroup, actionGetConversationGroup } from '@src/store/gpt/gpt.action';
import { toast } from 'react-toastify';
import { MuiDialogConfirm, MuiInputText } from '@src/components/common';
import { HOME_PATH } from '@src/constants/routes'
import VIcon from '@src/shared/icons/VIcon'
import XIcon from '@src/shared/icons/XIcon'
import { setConversationGroup, setLoadingSubmitDelete } from '@src/store/gpt/gpt.slice'
import { IConversationGroup } from '@src/store/type'
import CollapseIcon from '@src/shared/icons/CollapseIcon'
import { setCollapse } from '@src/store/auth/auth.slice'

interface ILayoutSidebarProps extends DrawerProps {
  className?: string
  items: ISidebarItem[]
  selectedKey: string
  setSelectedKey: (key: string) => void
}

const LayoutSidebar = ({ className, items, selectedKey, setSelectedKey, ...restProps }: ILayoutSidebarProps) => {
  const {groupId} = useParams();
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const groups = useAppSelector(({gpt}) => gpt.groups);
  const collapse = useAppSelector(({auth})=> auth.collapse);
  const location = useLocation()
  const [isLogged, setIsLogged] = useLocalStorage('isLogged', false)
  const [txtSummary, setTxtSummary] = useState('');
  const [openTxtInput, setOpenTxtInput] = useState(false);
  const [dialog, setDialog] = useState<any>(<></>);

  const theme = useAppSelector(({gpt}) => gpt.theme)
  const handleClickItem = useCallback(
    (key: string, path: string) => {
      const pathName = location.pathname
      if (pathName === path) return
      setSelectedKey(key)
      navigate(path)
    },
    [location.pathname, navigate, setSelectedKey],
  )

  const handleLogout = () => {
    isLogged && setIsLogged(false)
    dispatch(logoutUser())
  }

  const handleDeleteGroup = (groupId: number) => {
    const summary = groups?.find(ele => ele.id === groupId);

    setDialog(<MuiDialogConfirm 
      open={true} onCancel={() => {
        setDialog(<></>);
      } }
      
      onOk={() => {
        dispatch(setLoadingSubmitDelete(true));
        dispatch(actionDeleteConversationGroup(groupId)).then(async () => {
          try {
            const data = await dispatch(actionGetConversationGroup())
            dispatch(setLoadingSubmitDelete(false));
            if(data.length === 0){
              navigate(HOME_PATH+'new-chat')
            }else{
              navigate(HOME_PATH+data[0]?.id)
            }
            setDialog(<></>);
          } catch (error: any) {
            dispatch(setLoadingSubmitDelete(false));
            toast(error.message, { type: 'error' });
          }
        }).catch(err => {
          dispatch(setLoadingSubmitDelete(false));
          toast(err.message, { type: 'error' });
        });
      } }
      title='Delete Chat?'
      content={<>This will delete <strong>{summary?.summary}</strong></>} 
      type={'error'} 
         
    />)
    
  }

  const handleEditGroup = (groupId: number, oldSummary: string) => {
    const newGroups: IConversationGroup[] | undefined = groups?.map(((group) => {
      if(group?.id === Number(groupId)){
        return {
          ...group,
          summary: txtSummary
        }
      }
      return group
    }))
    dispatch(setConversationGroup(newGroups as IConversationGroup[]))
    setTxtSummary('');
    setOpenTxtInput(false);
    dispatch(actionEditConversationGroup(
      Number(groupId),
      {
        summary: txtSummary
      }
    )).then(() => {
      // nothing
    }).catch((err) => {
      const recoveryGroups: IConversationGroup[] | undefined = groups?.map(((group) => {
        if(group?.id === Number(groupId)){
          return {
            ...group,
            summary: oldSummary
          }
        }
        return group
      }))
      dispatch(setConversationGroup(recoveryGroups as IConversationGroup[]))
      toast(err.message, { type: 'error' })
    })
  }
  return (
    <>
      <Drawer
        variant="permanent"
        id="sidebar-position"
        className={twMerge('w-[330px] shrink-0 whitespace-nowrap transition-all duration-300 ease-in-out z-[99999]', `${className} ${collapse ? '': 'hidden'}`)}
        {...restProps}
       
      >
        <section className="flex h-page-header items-center h-[91px] justify-center"><IcLogo color={theme === 'dark' ? '#ffff' : undefined}/></section>

        <List className='dark:bg-slate-700 h-[100vh] max-h-[calc(100vh-160px)] overflow-y-scroll' id="side-bar-cover">
          {items?.map(({ disabled, key, name, path }) => {
            const summary = groups?.find(ele => ele.id === Number(key));
          
            return  (
              <ListItemButton
                key={key}
                selected={selectedKey === key}
                disabled={disabled}
                onClick={() => {
                  if(key !== 'new-chat'){
                    handleClickItem(key, path)
                  }
                }}
                className='h-[50px] flex justify-between'
              >
                <Box className={ groupId === `${key}` ? "overflow-hidden" : "min-w-[290px] max-w-[290px] overflow-hidden"}>
                    {key==='new-chat' ? (
                      <>
                        <ListItemText
                          primaryTypographyProps={{
                            className: `${selectedKey === key ? '' : 'text-grey'} flex justify-between font-body-regular text-base dark:text-white-0 ml-5`,
                          }}
                        > 
                          <span
                            onClick={() => {  
                              if(!disabled){
                                handleClickItem(key, path)
                              }                        
                            }}
                          className={`${selectedKey === key ? 'inline-block' : ''} flex min-w-[240px] items-center hover:cursor-pointer`}>
                            {name}
                          </span>
                         
                         
                          
                          <IconButton disabled={false} className='z-[9999999]' onClick={async () => {
                            await dispatch(setCollapse(false))
                          }}>
                            <CollapseIcon />
                          </IconButton>
                          
                          </ListItemText>
                      </>
                    ): (
                      <>
                      {groupId === `${key}` ? (
                        <>
                        {
                          openTxtInput ? (
                            <MuiInputText onChange={(e) => {
                              setTxtSummary(e.target.value)
                            }}
                            value={txtSummary}
                            />
                          ): (
                            <ListItemText
                                primary={name}
                                primaryTypographyProps={{
                                  className: `${selectedKey === key ? '' : 'text-grey'} font-body-regular text-base dark:text-white-0 ml-5`,
                                }}
                              />
                          )
                        }
                        </>
                      ): (
                        <ListItemText
                      primary={name}
                      onClick={() => handleClickItem(key, path)}
                      primaryTypographyProps={{
                        className: `${selectedKey === key ? '' : 'text-grey'} font-body-regular text-base font-normal dark:text-white-0 ml-5`,
                      }}/>
  
                      )}
                      
                      </>
                    )}
                  
                </Box>
                <Box>
                    {key==='new-chat' ? (
                      <></>
                    ): (
                      <>
                      {groupId === `${key}` ? (
                        <>
                        {openTxtInput ? (
  
                          <>
                          <IconButton onClick={() => {
                              if(summary?.summary !== txtSummary.trim()){
                                handleEditGroup(Number(groupId), summary?.summary as string)
                              }else{
                                setTxtSummary('');
                                setOpenTxtInput(false);
                              }
                              
                            }}>
                            <VIcon/>
                          </IconButton>
                          <IconButton onClick={() => {
                            setTxtSummary('');
                            setOpenTxtInput(false);
                          }}>
                            <XIcon/>
                          </IconButton> 
                          
                          </>
                        ): (
                          <>
                            <IconButton onClick={() => {
                              setTxtSummary(summary?.summary as string);
                              setOpenTxtInput(true)
                            }}>
                        <EditIcon/>
                      </IconButton>
                      <IconButton onClick={() => {
                        handleDeleteGroup(Number(key));
                      }}>
                        <DeleteIcon/>
                      </IconButton>  
                          </>
                        )}
                        
                        </>
                      ): (
                        <>
                        </>
                      )}
                      
                      </>
                    )}
                </Box>
              </ListItemButton>
            )
          }
          )}
        </List>
        <List className='absolute bottom-0 w-[100%]'>
        <ListItem button key={0} onClick={handleLogout} style={{height: '60px'}}>
          <ListItemIcon className='min-w-0'>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText className='text-grey ml-6'>
            <span className='font-heading text-base dark:text-dark-txtQuestion'>Log out</span>
          </ListItemText>
        </ListItem>
      </List>
      </Drawer>
      {dialog}
    </>
  )
}

export default LayoutSidebar
