import { ReactNode, useEffect } from 'react'
import { toast } from 'react-toastify'

import { isAfter } from 'date-fns'
import { FirebaseError } from 'firebase/app'
import { onAuthStateChanged } from 'firebase/auth'
import Cookies from 'js-cookie'

import useLocalStorage from '@src/hooks/useLocalStorage'
import { firebaseAuth } from '@src/libs/firebase'
import { setCurrentUser } from '@src/store/auth/auth.slice'
import { useAppDispatch } from '@src/store'
import { formatErrorFirebase } from '@src/utils'

import { AuthContext } from './auth.context'

interface IAuthProviderProps {
  children?: ReactNode
}

const AuthProvider = ({ children }: IAuthProviderProps) => {
  const dispatch = useAppDispatch()
  const [isLogged, setIsLogged] = useLocalStorage('isLogged', false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      try {
        if (user) {
          const idTokenResult = await user?.getIdTokenResult()

          const customClaims = idTokenResult.claims
          const permissions = (customClaims?.permissions as number[]) || []

          const isExpired = isAfter(new Date(), new Date(idTokenResult.expirationTime))

          if (isExpired) {
            const idToken = await user?.getIdToken(true)
            Cookies.set('idToken', idToken)
          } else {
            Cookies.set('idToken', idTokenResult.token)
          }
          await dispatch(
            setCurrentUser({
              name: user?.displayName,
              email: user?.email || '',
              permissions,
            }),
          )
          !isLogged && setIsLogged(true)
        } else {
          Cookies.set('idToken', '')
          setIsLogged(false)
          dispatch(setCurrentUser(null))
        }
      } catch (e) {
        const error = formatErrorFirebase(e as FirebaseError)
        toast(error.message, { type: 'error' })
      }
    })

    // Clean up function to unsubscribe from the listener when the component unmounts
    return () => {
      unsubscribe()
    }
  }, [dispatch, isLogged, setIsLogged])

  return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>
}

export default AuthProvider
