import { BodyUploadUrl, QueryConversations } from "@src/types/api";
import { AppThunk } from "..";
import { clientAbort, delConversationGroupAPI, editConversationGroupAPI, getConversationGroupAPI, getConversationsAPI, getLatestConvesation, getModelList } from "@src/api/gpt";
import { AxiosError } from "axios";
import { setConversationGroup, setModels } from "./gpt.slice";
import { IConversation, IConversationGroup, IModel } from "../type";
import { getUrlUpload } from "@src/api/upload";


export const actionGetConversationGroup= (
): AppThunk<Promise<IConversationGroup[]>> => {
  return async (dispatch) => {
    try {   
      const {data} = await getConversationGroupAPI();
      await dispatch(setConversationGroup(data?.data))
      return data?.data  
    } catch (error) {
      const err = error as AxiosError
      throw err?.response?.data;
    }
  };
};

export const actionGetConversations= (
  query: QueryConversations
): AppThunk<Promise<{
  data: IConversation[],
  totalCount: number
}>> => {
  return async () => {
    try {   
      const {data} = await getConversationsAPI(query);
      return {
        data: data.data,
        totalCount: data.totalCount
      }
    } catch (error) {
      const err = error as AxiosError
      throw err?.response?.data;
    }
  };
};

export const actionDeleteConversationGroup= (
  groupId: number
): AppThunk<Promise<boolean>> => {
  return async () => {
    try {   
      const {data} = await delConversationGroupAPI(groupId);
      return data.data
    } catch (error) {
      const err = error as AxiosError
      throw err?.response?.data;
    }
  };
};

export const actionEditConversationGroup= (
  groupId: number,
  body: {
    summary: string;
  }
): AppThunk<Promise<boolean>> => {
  return async () => {
    try {   
      const {data} = await editConversationGroupAPI(groupId, body);
      return data.data
    } catch (error) {
      const err = error as AxiosError
      throw err?.response?.data;
    }
  };
};

export const actionGetLatestConversation= (
 
): AppThunk<Promise<any>> => {
  return async () => {
    try {   
      const {data} = await getLatestConvesation();
      return data.data
    } catch (error) {
      const err = error as AxiosError
      throw err?.response?.data;
    }
  };
};

export const actionGetModels= (
 
  ): AppThunk<Promise<any>> => {
    return async (dispatch) => {
      try {   
        const {data} = await getModelList();
        // remove vision model
        const payload = data.data.filter((ele: IModel) => ele.value !== 'gpt-4-1106-vision-preview')
        await dispatch(setModels(payload));
        return payload
      } catch (error) {
        const err = error as AxiosError
        throw err?.response?.data;
      }
    };
  };

export const actionClientAborted= (

): AppThunk<Promise<any>> => {
  return async () => {
    try {   
      const {data} = await clientAbort();
      return data.data
    } catch (error) {
      const err = error as AxiosError
      throw err?.response?.data;
    }
  };
};

  export const actionGetUrlUpload= (
    body: BodyUploadUrl[]
  ): AppThunk<Promise<any>> => {
    return async () => {
      try {   
        const {data} = await getUrlUpload(body);
        return data.data
      } catch (error) {
        const err = error as AxiosError
        throw err?.response?.data;
      }
    };
  };