
import { Outlet } from 'react-router-dom'

const LayoutAuth = () => (
  <section className="h-screen tw-w-screen">
    <section className="flex h-full w-full items-center justify-center">
      <Outlet/>
    </section>
  </section>
)

export default LayoutAuth