import './Conversation.css';
import React, { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@src/store";
import {  actionGetConversations } from "@src/store/gpt/gpt.action";
import { IConversation } from "@src/store/type";
import { Box, LinearProgress, Typography } from "@mui/material";
import { selectAuthUser } from '@src/store/auth/auth.selector';
import LoadingPageIcon from '@src/shared/icons/LoadingPageIcon';
import QAndASet from './QAndASet';

interface IConversationModuleProps {
  groupId?: number | string,
  page: number;
  setPage: any;
  conversations: IConversation[];
  setConversations: any;
  totalPage: number;
  setTotalPage: any;
  isLoading: boolean;
  loadingPage: boolean;
  isNewChat: boolean
}

const LIMIT=5;
const Conversation = ({
  groupId,
  page,
  setPage,
  conversations,
  setConversations,
  totalPage,
  setTotalPage,
  isLoading,
  loadingPage,
  isNewChat
}: IConversationModuleProps) => {
  const currentUser = useAppSelector(selectAuthUser)
  const dispatch = useAppDispatch();
  const collapse = useAppSelector(({auth}) => auth.collapse);

  // handle load more
  useEffect(() => {
    if((page > 1) && (page <= totalPage)){
      dispatch(actionGetConversations({
        groupId: Number(groupId),
        page: page,
        limit: LIMIT
      })).then((res:any) => {
        if(page === totalPage){
          setPage(totalPage + 1);
        }
        const addtionalCons = res.data.reverse();
        setConversations((pre : any) => ([...addtionalCons,...pre]))
       
      }).catch((err) => {
        console.log('loading error', err)
      })
    }
  }, [page])

  const handleScrollEvent = () => {
    // Check if the user is at the top of the page
    const isAtTop = window.scrollY === 0;
    // Check if the user is scrolling up and not already loading data
    if (isAtTop) {
      setTotalPage((total: number) => {
        setPage((pre:number) => {
          if(total !== pre){
            return (pre + 1)
          }
          return pre
        })
        return total
      })
    }
  }

  
  useEffect(()=> {
    // Event listener for scrolling
    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent)
    }
  }, [])

  const data = useMemo(() => {
    if(conversations.length === 0){
      return <></>
    }
    return (
      <>
        {conversations.map((ele, index) => (
          <React.Fragment key={index}>
            <QAndASet 
              type={ele.type}
              name={currentUser?.name as string}
              question={ ele.type === 'image' ? JSON.parse(ele.question) :ele.question}
              answer={ele.answer}
              />
          </React.Fragment>
        ))}
      </>
    )
  }, [conversations, collapse])
   
  return (
    <>
    {
      loadingPage ? (
        <Box className="w-full flex justify-center items-center" sx={{
          height:'77vh'
        }}>
          <LoadingPageIcon />
      </Box>
      )
      : (
        <>
        {isNewChat ? (
          <>
            <Box className="text-center pt-5">
              <Typography className='text-base font-body-regular dark:text-dark-txtAnswer'>
                Start a conversation by typing in the text field below…
              </Typography>
            </Box>
          </>
        ): (
          <>
            {data} 
          </>
        )}
    {isLoading ? (
      <LinearProgress />
    ) : (
      <></>
    )}
    
        </>
      )
    }
   
    </>
  )
}

export default Conversation;