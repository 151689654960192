import { IConversationGroup, IModel} from '../type'

export interface IGPTState {
  groups: IConversationGroup[] | null,
  loadingSubmitDelete: boolean;
  theme:string;
  models: IModel[]
}

export const initialState: IGPTState = {
  groups: null,
  loadingSubmitDelete: false,
  theme: JSON.parse(localStorage.getItem('theme') || JSON.stringify('light')),
  models: []
}
