interface Props {
  size?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
  color?: string;
}
const ChatIcon = ({className}: Props) => {
  return (
    <svg 
      stroke="currentColor" 
      fill="none" 
      strokeWidth="2" 
      viewBox="0 0 24 24" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      className={`icon-sm ${className}`} 
      height="1em" 
      width="1em" 
      xmlns="http://www.w3.org/2000/svg">
      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z">
        </path>
    </svg>
  )
}

export default ChatIcon;