import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import Cookies from "js-cookie";

import { firebaseAuth } from "@src/libs/firebase";

export const getBaseQuery = (
  url: string = import.meta.env.VITE_APP_SERVER_URL ||
    "https://sso-service-be-test-tvpmccrtaa-as.a.run.app/api/v1",
) =>
  fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: (headers) => {
      const token = Cookies.get("idToken");

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  });

export const getBaseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseQuery = getBaseQuery();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // try to get a new token
    const user = firebaseAuth.currentUser;
    if (!user) return result;

    const idToken = await user.getIdToken(true); // Force refresh the token
    Cookies.set("idToken", idToken);
    result = await baseQuery(args, api, extraOptions);
  }
  return result;
};

export const baseApi = createApi({
  baseQuery: getBaseQueryWithReAuth,
  tagTypes: ["Users", "Roles", "Permissions"],
  endpoints: () => ({}),
});
