import { createAsyncThunk } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { firebaseAuth } from '@src/libs/firebase'


export const logoutUser = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
  try {
    await firebaseAuth.signOut()
    Cookies.remove('idToken')
    localStorage.setItem('isLogged', JSON.stringify(false));
    return true // Indicate successful logout
  } catch (error) {
    return rejectWithValue('Logout failed')
  }
})