import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material'
import { twMerge } from 'tailwind-merge'

export type TMuiInputTextAreaProps = TextareaAutosizeProps

const MuiInputTextArea = ({
  name,
  className,
  minRows,
  maxRows,
  ref,
  ...restProps
}: TextareaAutosizeProps) => (
  <TextareaAutosize
    name={name}
    minRows={minRows}
    maxRows={maxRows}
    ref={ref}
    className={twMerge(
      'bg-[#fff] border border-[#C7C9D9] rounded-md',
      'placeholder:text-sm placeholder:font-normal placeholder:text-[#8F90A6]',
      className,
    )}
    style={{
     
      outline: 'none', // Remove default focus outline
     
    }}
    
    {...restProps}
  />
)
export default MuiInputTextArea
