interface Props {
  width?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
  color?: string;
}
const SendIcon = ({className, width=18, height=18}: Props) => {
  return (
    <svg 
    width={width} 
    height={height} 
    viewBox="0 0 24 24" 
    fill="none" 
    className={"text-white dark:text-gray-900 "+className}>
      <path d="M7 11L12 6L17 11M12 18V7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
  )
}

export default SendIcon;