import { FC, ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { LOGIN_PATH } from "@src/constants/routes";
import Cookies from "js-cookie";
interface Props {
  children?: ReactElement;
}

const RouteNotFound: FC<Props> = () => {
  const token = Cookies.get("idToken");
  if (!token) {
    return <Navigate to={LOGIN_PATH} />;
  }
  return <Navigate to={'/'} />;
};

export default RouteNotFound;
