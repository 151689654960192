import Routes from './routers/Route'
import { Provider } from 'react-redux'
import AuthProvider from './contexts/auth/auth.provider'
import store from '@src/store'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  return (
    <>
   
    <Provider store={store}>
      <AuthProvider>
        <Routes />
        <ToastContainer />
      </AuthProvider>
    </Provider>
    </>
  )
}

export default App
