import { Control, Controller, ControllerProps, FieldError, FieldValues, Path } from 'react-hook-form'

import { twMerge } from 'tailwind-merge'

import MuiInputTextArea, {TMuiInputTextAreaProps} from '../input/input-textarea'
export type TFormInputProps<T extends FieldValues = FieldValues> = Omit<
TMuiInputTextAreaProps,
  'name'
> & {
  validation?: ControllerProps['rules']
  name: Path<T>
  parseError?: (error: FieldError) => string
  control?: Control<T>,
  ref?:any
}

const FormInputTextArea = <TFieldValues extends FieldValues = FieldValues>({
  validation = {},
  parseError,
  required,
  name,
  control,
  value,
  ref,
  
  ...restProps
}: TFormInputProps<TFieldValues>) => {

  if (required && !validation.required) {
    validation.required = 'This field is required'
  }

  return (
    <Controller
      name={name}
      control={control as any}
      rules={validation}
      render={({ field: { onChange, onBlur, ...fieldProps }, fieldState: {  } }) => {
        return (
          <MuiInputTextArea
            {...restProps}
            {...fieldProps}
            ref={ref}
            className={twMerge('w-full', restProps.className)}
            value={value}
            onChange={(ev) => {
              onChange(ev)
              if (typeof restProps.onChange === 'function') {
                restProps.onChange(ev)
              }
            }}
            onBlur={onBlur}
            required={required}
            
          />
        )
      }}
    />
  )
}

export default FormInputTextArea
