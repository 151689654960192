export const convertTimestamp = (timestamp: Date) => {
  const date = new Date(timestamp);

  const options: any = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return date.toLocaleString("en-GB", options);
};

export const getInitials = (fullName?: string) => {
  if (fullName) {
    const names = fullName.split(" ");
    return names
      .map((name) => name[0])
      .join("")
      .toUpperCase();
  }
  return undefined;
};

export const getLocalItem = (key: string, alternative: unknown) =>
  JSON.parse(localStorage.getItem(key) as string) ?? alternative

export const setLocalItem = (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value))