import { Box, Grid, IconButton } from "@mui/material";
import { UserAvatar } from "@src/components/common";
import CopyIcon from "@src/shared/icons/CopyIcon";
import { useAppSelector } from "@src/store";
import md from "@src/utils/markdown";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Image } from 'mui-image'

interface Props {
  name: string;
  question: any;
  answer: string;
  type: string
}
const QAndASet = (props: Props) => {
  const {name, answer, type, question} = props

  const collapse = useAppSelector(({auth}) => auth.collapse);

  const [, setCalcWidthQuestion] = useState<boolean>(false);
  const calcWidthQuestionPosition = () => {
    const widthQuestion = document.getElementById('question-position');
    if(widthQuestion){

      return widthQuestion.getBoundingClientRect()?.width;
    }
  }

  const handleCaclWidthCode = () => {
    setCalcWidthQuestion(pre => !pre)
  }

  useEffect(() => {
    window.addEventListener('resize', handleCaclWidthCode);
    return () => {
      window.removeEventListener('resize', handleCaclWidthCode)
    }
  }, [])

  useEffect(() => {
    setCalcWidthQuestion(pre => !pre)
  }, [collapse])

  const copyToClipboard = useCallback((code: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = code;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }, []);
  
  const renderAnswer = (answerText: string) => {
    const codeBlockRegex = /```(\w*)\n([\s\S]*?)```/g;
    const parts = [];
    let lastIndex = 0;
    parts.push(
      <div className='flex justify-between empty:hidden h-0'>
        <div className='flex w-full justify-end relative' style={{
          right: '-40px'
        }}>
          <IconButton className='hover:bg-transparent focus:bg-transparent active:bg-transparent px-[10.5px] py-[15px]' onClick={(e) => {
            let btn: any = e.target;
            const tagName = btn.tagName;
            if(tagName === 'svg'){
              btn = btn.parentElement
            }
            btn.innerHTML=`<svg 
                stroke="currentColor" 
                fill="none" 
                class="dark:text-dark-txtAnswer"
                stroke-width="2" 
                viewBox="0 0 24 24" 
                stroke-linecap="round" 
                stroke-linejoin="round"
                height="16" 
                width="16" 
                xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>`
              setTimeout(()=> {
                btn.innerHTML = `<svg stroke="currentColor" class="dark:text-dark-txtAnswer" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>`
              }, 1500)
            copyToClipboard(answerText)
          }}>
            <CopyIcon/>
          </IconButton>
        </div> 
      </div>
    )
    let match;
    while ((match = codeBlockRegex.exec(answerText)) !== null) {
      const [, language, code] = match;
      const start = match.index;
      if (start > lastIndex) {
   
        parts.push(
          <div className='markdown prose dark:text-dark-txtAnswer'>
            <div dangerouslySetInnerHTML={{ __html: md.render(answerText.slice(lastIndex, start)) }} /> 
          </div>
        );
      }

      parts.push(
        <div className="mt-2 mb-2"
          style={{
            maxWidth: `${calcWidthQuestionPosition()}px`
          }}
        >
          <div  
            className='
            flex 
            items-center 
            relative 
            text-gray-200 
            bg-gray-800 
            gizmo:dark:bg-token-surface-primary 
            px-4 
            py-2 
            font-heading 
            justify-between 
            rounded-t-md
            '
            >
            <div >{language}</div>
            <button onClick={(e) => {
              let btn: any = e.target;
              const tagName = btn.tagName;
              if(tagName === 'SPAN'){
                btn = btn.parentElement
              }
              btn.innerHTML=`
              <svg 
                stroke="currentColor" 
                fill="none" 
                stroke-width="2" 
                viewBox="0 0 24 24" 
                stroke-linecap="round" 
                stroke-linejoin="round"
                height="16" 
                width="16" 
                xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg><span class="h-[24px]">Copied!</span>
              `

              setTimeout(()=> {
                btn.innerHTML = `
                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg><span class="h-[24px]">Copy code</span>
                `
              }, 1500)

              copyToClipboard(code)}} 
              className='
              flex 
              ml-auto 
              gizmo:ml-0 
              gap-2 
              items-center
              max-h-[24px]
              '>
                <CopyIcon className='mr-1 mb-[3px]'/>
                Copy code
            </button>
          </div>
          <SyntaxHighlighter  language={language} style={vscDarkPlus}>
            {code}
          </SyntaxHighlighter>
        </div>
      );

      lastIndex = codeBlockRegex.lastIndex;
    }

    if (lastIndex < answerText.length) {
      parts.push(
        <div className='markdown prose dark:text-dark-txtAnswer'>
           <div dangerouslySetInnerHTML={{ __html:md.render(answerText.slice(lastIndex)) }} /> 
        </div>
      );
    }

    return <>{parts?.map((ele, index) => (
      <React.Fragment key={index + Math.random()}>
        {ele}
      </React.Fragment>
    ))}</>;
  };

  return (
    <>
    <Grid container justifyContent={'center'} className='p-4 dark:bg-dark-bgQuestion'>
        <Grid item xs={1} className='flex justify-center pt-1 max-w-[60px]'>
            <UserAvatar fullName={name}/>
        </Grid>
        <Grid item xs={8} sx={{
          maxWidth:(window.innerWidth >= 1620 ? 1000 : 685)+ 'px !important'
        }} id="question-position">
          <Box className={"pt-[6px] pl-[0.75rem] pr-2 pb-0 " + (type === 'image' ? 'flex flex-col-reverse' : '')} >
          {type === 'image' ? (
            <>
            {question.map((ele: {
              type: string,
              text?: string,
              image_url?:{
                url: string
              }
            }, index: number) => {
              if(ele.type === 'image_url'){
                return (
                 
                  <Image showLoading={true} key={index} src={ele.image_url?.url as string} className="max-w-[100%]"/>
                
                )
              }

              return (
                <div key={index} className="text-base dark:text-dark-txtQuestion font-body-regular white-space-break-spaces scrollbar-x-custom overflow-x-hidden mt-2">
                  {ele.text}
                </div>
              )

            })}
            </>
          ): (
          <div className="text-base dark:text-dark-txtQuestion font-body-regular white-space-break-spaces scrollbar-x-custom overflow-x-hidden">
            {question}
          </div>

          )}
          
        </Box>
    </Grid>
    </Grid>
    <Grid container justifyContent={'center'} className='bg-grey-gpt p-4 dark:bg-dark-bgAnswer dark:border-transparent' sx={{
      border: '1px solid #e5e5e5'
    }}>
      <Grid item xs={1} className='flex justify-center pt-1 max-w-[60px]'>
        <UserAvatar/>
      </Grid>
        <Grid item xs={8} sx={{
          maxWidth:(window.innerWidth >= 1620 ? 1000 : 685)+ 'px !important'
        }}>
          <Box className="pt-[6px] pl-[0.75rem] pr-2 pb-0" >
        <div  className="text-base font-body-regular white-space-break-spaces">
          {renderAnswer(answer)}
        </div>
    </Box>
    </Grid>
    </Grid>
    </>
  )
}
export default QAndASet;