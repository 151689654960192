import { FC, ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

interface Props {
  children?: ReactElement;
}

const UnauthRoute: FC<Props> = () => {
  const [isLogged] = useLocalStorage("isLogged", false);
  if (!isLogged) {
    return <Outlet />;
  }
  return <Navigate to={'/'} />;
};

export default UnauthRoute;
