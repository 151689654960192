
import { MuiLayoutHeader, MuiLayoutSidebar } from '../common'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useSidebar } from '@src/hooks/useSideBar'
import { useAppDispatch, useAppSelector } from '@src/store'
import { selectAuthUser } from '@src/store/auth/auth.selector';
import { HOME_PATH } from '@src/constants/routes'
import { useEffect } from 'react'
import { actionGetLatestConversation } from '@src/store/gpt/gpt.action'
import { Box, IconButton } from '@mui/material'
import LoadingPageIcon from '@src/shared/icons/LoadingPageIcon'
import CollapseIcon from '@src/shared/icons/CollapseIcon'
import { setCollapse } from '@src/store/auth/auth.slice'

const PageLayout = () => {
  const groups = useAppSelector(({gpt}) => gpt.groups);
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if(location.pathname === '/'){
      dispatch(actionGetLatestConversation()).then(data => {
        navigate(`${HOME_PATH}${data?.groupId}`)  
      }).catch(() => {
        navigate(`${HOME_PATH}new-chat`)
      });
    }
  }, [location.pathname])
  const { sidebarItems, selectedListItem, setSelectedListItem } = useSidebar({
    currentPath: location.pathname,
  })
  const currentUser = useAppSelector(selectAuthUser)
  
  return (
    <>
      { groups ? (
        <section className="flex justify-start items-start dark:bg-slate-700">
        <MuiLayoutSidebar
          items={sidebarItems}
          selectedKey={selectedListItem}
          setSelectedKey={setSelectedListItem}
          classes={{ paper: 'shadow-sidebar w-[330px]' }}
        />
        <section className="w-full h-full dark:bg-slate-700 dark:text-white-0 overflow-x-hidden">
          <MuiLayoutHeader
            logo={
            <IconButton onClick={() => {
              dispatch(setCollapse(true))
            }}>
              <CollapseIcon height={24} width={24} color='black'/>
            </IconButton>}
            position="fixed"
            className="w-full h-[91px] z-[1201] fixed"
            username={currentUser?.name || currentUser?.email}
          />
          <main className="grow mt-[5rem] pt-4 pb-4 dark:bg-slate-700 dark:text-white-0 min-h-[calc(100vh-64px)]">
            <Outlet/>
          </main>
        </section>
      </section>
      ): (
        <Box className="w-full flex justify-center items-center" sx={{
          height:'100vh'
        }}>
          <LoadingPageIcon />
        </Box>
      )

      }
      
    </>
)}

export default PageLayout
