import { useEffect, useState } from 'react';
import { Grid, Box, IconButton, FormControl, InputLabel, Select, MenuItem, Tooltip } from "@mui/material";
import { MuiFormInputTextArea } from "@src/components/common";
import SendIcon from "@src/shared/icons/SendIcon";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from '@src/store';
import { setCollapse } from '@src/store/auth/auth.slice';
import { actionClientAborted, actionGetConversationGroup, actionGetLatestConversation, actionGetModels, actionGetUrlUpload } from '@src/store/gpt/gpt.action';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import StopIcon from '@src/shared/icons/StopIcon';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useNavigate, useParams } from 'react-router-dom';
import { HOME_PATH } from '@src/constants/routes';
import { twMerge } from 'tailwind-merge';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IModel } from '@src/store/type';
import ImagesInput from './ImageInput';
import { setLocalItem } from '@src/utils';
interface IFileBody 
{
  fileName: string;
  fileType: string;
}

interface Props {
  model: string;
  setModel: (model: string) => void;
  handleAsk: () => void;
  isLoading: boolean;
  question: string;
  setQuestion: (str: string) => void;
  loadingDot: boolean;
  cancelQuestion: any;
  imgs: [string] | null;
  setImgs: any
}
const InputTextChat = ({
  model,
  setModel,
  handleAsk,
  isLoading,
  question,
  setQuestion,
  loadingDot,
  cancelQuestion,
  imgs,
  setImgs
}: Props) => {
  const {
    control,
    handleSubmit,
    formState: { },
  } = useForm<{question: string}>({
    defaultValues: {
      question: ''
    },
  })
  const {groupId} = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const collapse = useAppSelector(({auth}) => auth.collapse)
  const models = useAppSelector(({gpt}) => gpt.models)
  const [, setWindowWidth] = useState(window.innerWidth);
  const [, setIsCalcButtonSubmit] = useState<boolean>(false)
  const [newModels, setNewModels] = useState<IModel[]>([]);
  const [,setForceReloadContent] = useState<boolean>(false);

  useEffect(() => {
    if(models.length > 0){
      setNewModels([...models])
    }
  }, [models])

  useEffect(() => {
    dispatch(actionGetModels()).then(data => {
      if(!data.some((item: any) => item.value === model)){
        setModel(data[0]?.value)
        localStorage.setItem('selectedModel', JSON.stringify(data[0]?.value))
      }
    });
    if(window.innerWidth < 720){
      dispatch(setCollapse(false));
    }
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if(window.innerWidth < 720){
        dispatch(setCollapse(false));
      }
      setForceReloadContent((pre) => !pre);
    };

    window.addEventListener('resize', handleResize);
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsCalcButtonSubmit(pre => !pre)
  }, [collapse])

  
   //cacl width button submit
   const calcButtonSubmitPosition = () => {
    const textArea = document.getElementById('input-chat-position');
    if(textArea){
      return (textArea.getBoundingClientRect()?.width / 2) + 9 + (window.innerWidth >= 1620 ? 12 : 0);
    }
    return 10000;
  }

  const handleUploadImg = async (e: any) => {
    const maxFiles = 5;
   
    if (e.target.files.length > maxFiles) {
      toast.error(`You can only upload a maximum of ${maxFiles} images.`);
      e.target.value = null;
      return;
    } else {
      let files: File[] = Object.values(e.target.files);

      const images : IFileBody[] = [];
      const validFiles: File[] = [];
      files.forEach(file => {
        if (file.size > 2 * 1024 * 1024) {
          toast.error(`${file.name} should be under 2MB.`)
        }else{
          images.push({
            fileName: file.name,
            fileType: file.type
          })
          validFiles.push(file)
        }
      })
  
      try {
        const { urls } = await dispatch(actionGetUrlUpload(images))
        await Promise.all(urls.map((url: string, index: number) => {
          return axios.put(url, validFiles[index], {
            headers: {
              'Content-Type': `${validFiles[index].type}`
            }
          });
        }))
        setImgs(urls.map((url: string) => url.split('?')[0]));
        e.target.value = null;
      } catch (error) {
        console.log('check error', error)
        e.target.value = null;
      }
    }
  }


  return (
    <>
      <Grid sx={{
      width:collapse ? 'calc(100% - 320px)' : '100%'
    }} container className="
      fixed 
      pb-8
      pt-1  
      bottom-0 
      bg-white-0 
      setting-position
      justify-center
      dark:bg-dark-bgCoverInputText
      ">
      <Grid item xs={9} xl={12} className="flex justify-center items-center pr-[10px]">
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel className='text-xs font-heading dark:text-dark-txtAnswer'>Model</InputLabel>
      
        {models.length > 0 ? (<>
          <Select
        className='h-[25px] text-xs font-body-regular dark:border-dark-txtAnswer dark:text-dark-txtAnswer'
        value={
          (imgs && imgs.length > 0)  ? 'gpt-4o' :
           
          model
          
        }
        disabled={(imgs && imgs.length > 0)  ? true : false}
        label="Model"
        onChange={(e) => {
          if(!imgs?.length){
            setModel(e.target.value);
            localStorage.setItem('selectedModel', JSON.stringify(e.target.value))
          }
          
        }}
      >
          {((imgs && imgs.length > 0) ? newModels : models).map((ele, index) => {
            return (
              <MenuItem key={index} value={ele.value}>{ele.label}</MenuItem>
            ) 
            
          })}

        </Select>
        {models.find(ele => ele.value === model)?.tooltip ? (
          <>
          <Box className="absolute right-[-25px] top-[-8%]">
            <Tooltip title={(imgs && imgs.length > 0) ? newModels.at(-1)?.tooltip :models.find(ele => ele.value === model)?.tooltip}>
              <PriorityHighIcon
              fontSize='small' 
              className='
              dark:text-dark-txtAnswer
              border
              dark:border-dark-txtAnswer
              border-dark-borderIconLightTheme
              border-solid
              rounded-full
              p-[3px]
              hover:cursor-pointer
              text-base
              '
              
              />

            </Tooltip>
        </Box>
          </>
        ): (
          <></>
        )}
        
        </>): (
          
          <></>
        )}
  
    </FormControl>
      </Grid>
      <Grid item xs={9} xl={12} className={`rounded-xl dark:bg-dark-bgInputText pr-0`}
      sx={{
        maxWidth:'calc(90ch + 40px)'+ ' !important',
        border:'1px solid #c4c6d5',
        marginLeft: (window.innerWidth >= 1620 ? 23 : 0) + 'px'
      }}
      >
        <Box className="w-full flex">
          <ImagesInput imgs={imgs} setImgs={setImgs}/>
        </Box>
        <form onSubmit={handleSubmit(handleAsk)} className='flex items-end'>
          <Box className='pb-[10px] pl-[5px] hover:cursor-pointe' onClick={() => {
            const inputUpload = document.getElementById('input-upload-img');
            if(inputUpload){
              inputUpload.click();
            }
          }}>
            <AttachFileIcon className='hover: cursor-pointer'/>
            <input
              id='input-upload-img'
              type="file"
              onChange={handleUploadImg}
              accept="image/png, image/jpeg"
              multiple={true}
              hidden
            />
          </Box>
          <MuiFormInputTextArea 
            id='input-chat-position'
            disabled={isLoading}
            name="question" 
            control={control} 
            className="
            h-[44px]
            p-6
            text-base
            m-0 
            w-[100%]
            resize-none 
            bg-transparent py-[10px] 
            focus:ring-0 
            focus-visible:ring-0 
            dark:bg-dark-bgInputText 
            pl-1
            pr-[45px]
            active:border-transparent
            font-body-regular
            gizmo:md:py-3.5 
            gizmo:placeholder-black/50 
            gizmo:dark:placeholder-white/50 
            placeholder:text-base
            scrollbar-custom
            border-none
            rounded-xl

            " 
            placeholder="Ask a question..."
            maxRows={10}
            minRows={1}
            onChange={e => {
              setLocalItem('questionAsking', e.target.value)
              setQuestion(e.target.value)
            }}
            value={question}
            />
          <Box className="absolute bottom-[38px]" sx={{
            right: `calc(50% - ${collapse ? calcButtonSubmitPosition() : calcButtonSubmitPosition()}px)`
          }}>
            {loadingDot ? (
              <>
               <IconButton
              sx={{
                "&:hover":{
                  background: "transparent"
                }
              }}
              
              onClick={() => {
                dispatch(actionClientAborted())
                cancelQuestion.abort('cancelled stream');
                if(groupId==='new-chat'){
                  setTimeout(async() => {
                    try {
                      const data = await dispatch(actionGetLatestConversation());
                      await dispatch(actionGetConversationGroup());     
                      navigate(`${HOME_PATH}${data?.groupId}`)  
                    } catch (error) {
                      navigate(HOME_PATH+'new-chat')
                    }
                  }, 1000)
                  
                }
              }}
              className='min-w-[32px] min-h-[32px] rounded-full dark:border-gray-200 border border-solid'>
                <StopIcon />
            </IconButton>
              </>
            ): (
              <>
              <IconButton
              id="btn-submit-input"
              
              disabled={!question || isLoading ? true : false}
              onClick={() => {
                handleAsk();
              }}
              className={twMerge('min-w-[24px] min-h-[24px]rounded-md',
              'rounded-md dark:hover:bg-white', 
              'dark:disabled:hover:bg-transparent',
              'disabled:opacity-10 disabled:text-white disabled:bg-black-1',
               ' text-white border hover:bg-gray-900 bg-gray-900', 
               'border-black rounded-lg dark:border-white dark:bg-white',
               'transition-colors'
              )}>
                <SendIcon/>
            </IconButton>
              </>
            )}
            
          </Box>
        </form>

      </Grid>
      
    </Grid>
    </>
  )
}

export default InputTextChat