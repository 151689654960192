import { Tooltip, TooltipProps } from '@mui/material'

export interface IMuiTooltipProps extends TooltipProps {
  arrow?: boolean
}

const MuiTooltip = ({ title, arrow = false, ...props }: IMuiTooltipProps) => (
  <Tooltip title={title} arrow={arrow} {...props} />
)

export default MuiTooltip