import { Box, IconButton } from "@mui/material";
import XIcon from "@src/shared/icons/XIcon";
import Image from 'mui-image';

interface Props  {
  imgs: [string] | null;
  setImgs: any
}

const ImagesInput = ({imgs, setImgs}: Props) => {
  return (
    <>
    {imgs ? (
      <>
      {imgs.map((img, index) => {
        return (
          
          <Box className="relative" key={index}>
            <IconButton className='absolute right-[0px] z-10 p-[2px] bg-dark-bgDisabled hover:z-30 hover:bg-dark-bgDisabled '
              onClick={() => {
                setImgs((pre: [string]) => {
                  return pre.filter(url => url !== img)
                });
              }}
      >
        <XIcon className="w-[16px] h-[16px]"/>
      </IconButton>
      <Box className="z-20 hover:z-0 hover:cursor-pointer" onClick={() => {
        window.open(img, '_blank')
      }}>
        <Image
          className='p-2 rounded-xl' 
          src={img}
          showLoading={true}
          height={'80px'} 
          width={'80px'}
        />
      </Box>    
          </Box>
        )
      })}
      </>

    ): (<></>)}
    </>
  )
}

export default ImagesInput;